import { Component } from '@angular/core';
import { TracingService } from 'src/services/tracing.service';

@Component({
  selector: 'app-landing-page-insurance',
  templateUrl: './landing-page-insurance.component.html',
  styleUrls: ['./landing-page-insurance.component.scss']
})
export class LandingPageInsuranceComponent {
  author = {
    "Firstname": "Lars",
    "Lastname": "Branscheid",
    "Image": "https://screcruiting.blob.core.windows.net/publicassets/lars-avatar.jpg"
  }
  
  constructor(public tracingService : TracingService) { }

  goToBooking()
  {    
    window.open(this.tracingService.getDemoLink(), '_blank').focus();
  }
}
