<section class="blog-post">

  <div class="container mb-6">
    <div class="row">

      <div class="col-12 col-md-8 col-lg-8 offset-lg-0">

        <div class="post-header">
          <div class="h5 mb-3">#activesourcing</div>
          <h2 class="mb-5 heading-xl">
            KI-Recruiting-Service für <br> die Beratungsbranche
          </h2>
        </div>

        <div class="post-meta">
          <div class="row align-items-center">

            <div class="col-auto">
              <div class="row align-items-center">
                <div class="image col-auto">
                  <sc-avatar size="xs" [user]="author"></sc-avatar>
                </div>
                <div class="name col ms-n3">
                  <div class="name fw-bold">Lars Branscheid</div>
                </div>
              </div>

            </div>

            <div class="col-auto">
              <div class="timestamp">27.12.2024</div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="container mb-6">

    <div class="row">

      <div class="col-12 mt-6 mt-lg-7">
        <div class="video-container ratio ratio-16x9">
			<video controls>
				<source src="assets/video/exxeta.mp4" type="video/mp4">
				Ihr Browser unterstützt den HTML5 Video Tag nicht.
			  </video>
        </div>
      </div>

      <!-- <div class="col-12">
        <img src="/assets/img/ai.jpg" class="img-fluid post-banner" />
      </div> -->

    </div>

  </div>

  <div class="container">

    <div class="row">

      <div class="col-12 col-lg-6 offset-lg-3">

        <div class="post-content">

          <p class="font-weight-bold mb-6">
            Die Recruiter:innen der Exxeta haben viele Aufgaben - das mühselige Aufsuchen und Anschreiben von Kandidat:innen im Active Sourcing gehört nicht mehr dazu!
            Im Video erfährst du, wie unsere KI das Active Sourcing für Exxeta übernimmt - von der Suche und Ansprache bei LinkedIn, Xing und Co. bis zur
            Nachqualifizierung der Bewerber:innen.
          </p>

          <h4>
            Besseres Recruiting mit KI
          </h4>

          <p class="mb-6">
            Mit unserem Service nutzt du die Vorteile von KI für dein Recruiting<br>
            - ohne deine Prozesse ändern zu müssen.
          </p>

          <h4>
            Full-Service
          </h4>
          <p class="mb-6">
            Unsere Recruiting-Expert:innen begleiten dich als feste Ansprechperson und sorgen mit ihrem Know-how für eine kontinuierliche Optimierung deiner Projekte.
          </p>

          <h4>
            Wegweisende KI
          </h4>
          <p class="mb-6">
            Die erste KI, die Stellen und Profile besser versteht als ein Mensch. Von Grund auf neu entwickelt von skillconomy.
          </p>

          <h4>
            Candidate Experience
          </h4>
          <p class="mb-6">
            Wir gehen über die Individualisierung von Nachrichten hinaus und schaffen für deine Bewerber:innen eine begeisternde Candidate Experience.
          </p>

          <h4>
            Kosten
          </h4>
          <p class="mb-6">
            Unseren innovativen KI-Recruiting-Service kannst du ab 1.400€ pro Stelle buchen.
          </p>

        </div>

        <div class="row align-items-center mt-5">
          <div class="col-auto">
            <button class="btn btn-lg btn-round btn-primary" (click)="goToBooking()">Jetzt Demo buchen!</button>
          </div>
        </div>

        <!-- <a (click)="back();" class="link-arrow left text-uppercase small">Zurück</a> -->

      </div>

    </div>

  </div>

</section>
