import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutTopnavigationComponent } from './layouts/layout-topnavigation/layout-topnavigation.component';
import { HomeComponent } from './views/home/home.component';
import { PrivacyNoticeComponent } from './views/privacy-notice/privacy-notice.component';
import { ImprintComponent } from './views/imprint/imprint.component';
import { ContactComponent } from './views/contact/contact.component';
import { CompanyComponent } from './views/company/company.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { EbnerCandidateTrackComponent } from './views/ebner-stolz/ebner-stolz.component';
import { UnsubscribeComponent } from './views/unsubscribe/unsubscribe.component';
import { LayoutHeaderNoNavComponent } from './layouts/layout-header-no-nav/layout-header-no-nav.component';
import { BookingConfirmationComponent } from './views/booking-confirmation/booking-confirmation.component';
import { FeedbackComponent } from './views/feedback/feedback.component';
import { WebinarComponent } from './views/webinar/webinar.component';
import { LandingPageConsultingComponent } from './views/landing-page-consulting/landing-page-consulting.component';
import { LandingPageInsuranceComponent } from './views/landing-page-insurance/landing-page-insurance.component';
import { LandingPageInternetComponent } from './views/landing-page-internet/landing-page-internet.component';
import { LandingPageGenericComponent } from './views/landing-page-generic/landing-page-generic.component';


const routes: Routes = [
  {
    path: '',
    component: LayoutTopnavigationComponent,
    children: [
      {
        path: '404',
        component: NotFoundComponent
      },
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'unternehmen',
        component: CompanyComponent
      },
      {
        path: 'datenschutz',
        component: PrivacyNoticeComponent
      },
      {
        path: 'impressum',
        component: ImprintComponent
      },
      {
        path: 'kontakt',
        component: ContactComponent
      },
      {
        path: 'unsubscribe/:secret',
        component: UnsubscribeComponent
      },
      {
        path: 'buchung',
        component: BookingConfirmationComponent
      },
      {
        path: 'webinar',
        component: WebinarComponent
      },
      {
        path: 'consulting',
        component: LandingPageConsultingComponent
      },
      {
        path: 'insurance',
        component: LandingPageInsuranceComponent
      },
      {
        path: 'internet',
        component: LandingPageInternetComponent
      },
      {
        path: 'ai',
        component: LandingPageGenericComponent
      }
    ]
  }, 
  {
    path: 'feedback/:secret',
    component: FeedbackComponent
  },
  {
    path: '',
    component: LayoutHeaderNoNavComponent,
    children: [
      {
        path: 'ebner-stolz-track/:secret',
        component: EbnerCandidateTrackComponent,
        data: { variant: 'business' }
      },
      {
        path: 'ebner-stolz-track-it/:secret',
        component: EbnerCandidateTrackComponent,
        data: { variant: 'it' }
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
