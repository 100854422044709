<section class="sc-hero">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-12 mt-lg-6">
        <h1 class="heading-xl heading-serif">skillconomy ist dein<br />KI-Recruiting-Partner</h1>
      </div>
      <div class="col-12 col-md-8 col-lg-6 col-xl-6">
        <p class="larger">
          Mit unserer wegweisenden KI und ausgewiesenen Recruiting-Expert:innen besetzen wir deine Fach- und Führungspositionen.
        </p>
      </div>
      
      
      <div class="col-12 mt-6 mt-lg-7">
        <div class="video-container ratio ratio-16x9">
          <video poster="assets/video/produkt.jpg" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
            <source src="assets/video/Teaser.mp4" type="video/mp4">
            Ihr Browser unterstützt den HTML5 Video Tag nicht.
          </video>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mt-6 mt-lg-7">
	  <div class="col-auto">
		<button class="btn btn-primary btn-round btn-lg"  scAnchorLink="book" anchorReferencePosition="middle" anchorTargetPosition="middle">Jetzt Demo buchen</button>
	  </div>
	</div>

  <svg class="blob" preserveAspectRatio="xMidYMax slice" viewBox="0 0 2155 846" xmlns="http://www.w3.org/2000/svg">
    <path d="m1191.3615 8.99009608c179.46072-16.8766341 326.14441-13.66197326 461.28581 41.76376842 100.15004 41.0744336 177.12103 99.0858515 242.95287 164.6356965 110.83204 110.380751 191.32318 229.634341 222.6478 363.854679 14.79046 63.323997 41.86408 173.723998 35.30144 265.707399l-2153.54942 2.33225c5.67652474-179.964518 79.8652714-347.311189 217.501604-501.793772 95.947064-107.69315 235.551844-180.729117 404.604463-224.294008 112.610447-29.0204612 228.891506-51.4855357 344.590051-74.2791842 83.536802-16.4541818 168.819232-28.6194669 224.665382-37.92682872z" fill="#eef4fb" fill-rule="evenodd" transform="matrix(-1 0 0 -1 2154.64852 846.000001)" /></svg>

</section>

<!-- 
<section>
  <div class="particles">
    <div class="particle rellax" data-rellax-speed="1.8"></div>
    <div class="particle rellax" data-rellax-speed="0.8"></div>
    <div class="particle rellax" data-rellax-speed="0.5"></div>
    <div class="particle rellax" data-rellax-speed="0.4"></div>
    <div class="particle rellax" data-rellax-speed="1.6"></div>
  </div>

  <div class="container">

    <div class="row align-items-center">

      <div class="col-12 col-lg-5 mt-lg-n5">

        <div class="row">

          <div class="col-12">
            <h1 class="heading-xl heading-serif">
              Active Sourcing.<br>
              Weitergedacht.
            </h1>
          </div>

          <div class="col-12 col-md-9 col-lg-10">
            <p class="larger">
              Mit skillconomy finden Sie innerhalb von 14 Tagen passende, wechselbereite Bewerber:innen – auch für schwer zu besetzende Stellen.
            </p>
          </div>

          <div class="d-none d-md-block col-12 mt-3 mb-4 mb-lg-0">
            <button class="btn btn-primary btn-round btn-lg" (click)="scroll.scrollTo('contact')">Jetzt kennenlernen</button>
          </div>

          <div class="d-md-none col-12 mt-3 mb-4 mb-lg-0">
            <button class="btn btn-primary btn-round" (click)="scroll.scrollTo('contact')">Jetzt kennenlernen</button>
          </div>

        </div>

      </div>

      <div class="col-11 mt-3 mb-5 mt-lg-0 mb-lg-0 offset-1 col-md-8 offset-md-4 col-lg-7 offset-lg-0">

        <div class="video-blob-wrapper">

          <div class="video-container">
            <video poster="assets/video/moodshots_startseite.jpg" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
              <source src="assets/video/moodshots_startseite.mp4" type="video/mp4">
            </video>
          </div>

          <svg height="0" width="0">
            <clipPath id="blobClipPath" clipPathUnits="objectBoundingBox">
              <path d="M0.85204,0.09377c0.08811,0.07681,0.13329,0.20785,0.14504,0.35063
                          C1.00883,0.58717,0.98691,0.74148,0.8988,0.84879C0.8107,0.9561,0.65639,1.01642,0.5283,0.98999
                          c-0.1281-0.02643-0.22976-0.13939-0.32781-0.2467C0.10267,0.63597,0.00846,0.53431,0.00055,0.42473
                          c-0.00791-0.1098,0.07026-0.2275,0.16808-0.30431c0.09782-0.07681,0.21553-0.11273,0.33978-0.11929
                          C0.6329-0.00564,0.76393,0.01695,0.85204,0.09377L0.85204,0.09377z" />
            </clipPath>
          </svg>
        </div>
      </div>

    </div>
  </div>

  <svg class="blob" preserveAspectRatio="xMidYMax slice" viewBox="0 0 2155 846" xmlns="http://www.w3.org/2000/svg">
    <path d="m1191.3615 8.99009608c179.46072-16.8766341 326.14441-13.66197326 461.28581 41.76376842 100.15004 41.0744336 177.12103 99.0858515 242.95287 164.6356965 110.83204 110.380751 191.32318 229.634341 222.6478 363.854679 14.79046 63.323997 41.86408 173.723998 35.30144 265.707399l-2153.54942 2.33225c5.67652474-179.964518 79.8652714-347.311189 217.501604-501.793772 95.947064-107.69315 235.551844-180.729117 404.604463-224.294008 112.610447-29.0204612 228.891506-51.4855357 344.590051-74.2791842 83.536802-16.4541818 168.819232-28.6194669 224.665382-37.92682872z" fill="#eef4fb" fill-rule="evenodd" transform="matrix(-1 0 0 -1 2154.64852 846.000001)" /></svg>
</section> -->
