<section>

  <div class="container mb-6">
    <div class="row">

      <div class="col-12 col-md-8 col-lg-8 offset-lg-0">

        <div class="post-header">
          <div class="h5 mb-3">#knowledge</div>
          <h2 class="mb-5 heading-xl">
            Kostenfreies Webinar: <br> KI-Recruiting

          </h2>
        </div>

        <div class="post-meta">
          <div class="row align-items-center">

            <div class="col-auto">
              <div class="row align-items-center">
                <div class="image col-auto">
                  <sc-avatar size="xs" [user]="author"></sc-avatar>
                </div>
                <div class="name col ms-n3">
                  <div class="name fw-bold">Lars Branscheid</div>
                </div>
              </div>

            </div>

            <div class="col-auto">
              <div class="timestamp">07.01.2025</div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="container-fluid mb-6">

    <div class="row">

      <div class="col-12">
        <img src="/assets/img/ai.jpg" class="img-fluid post-banner" />
      </div>

    </div>

  </div>

  <div class="container">

    <div class="row">

      <div class="col-12 col-lg-6 offset-lg-3">

        <div class="post-content">

          <p class="font-weight-bold mb-6">
            In der derzeitigen konjunkturellen Lage bleibt der Druck auf HR-Teams hoch, trotz begrenzter Ressourcen ambitionierte
            Recruiting-Ziele zu erreichen. Künstliche Intelligenz kann hierbei ein möglicher Ausweg sein: Sie bietet neue, kosteneffiziente
            Möglichkeiten, die passenden Talente schneller und gezielter zu finden. KI ist nicht ohne Grund auf Platz 1 der relevanten Themen für 2025.
          </p>

          <h3>
            Unsere Erfahrung – dein Vorsprung
          </h3>

          <p class="mb-6">
            Vor 10 Jahren haben wir mit unseren Such-Algorithmen und stellenspezifischen Jobsites den Active Sourcing Markt in Deutschland
            geprägt. Seitdem besetzen wir mit Hilfe innovativer Verfahren Fach- und Führungspositionen für unsere Kunden. Unsere einzigartige
            Datenbasis haben wir im letzten Jahr dafür genutzt, eine eigene Recruiting-KI zu entwickeln und auf über sechs Milliarden
            Datenpunkten zu trainieren.<br><br>
            In unserem kostenfreien Webinar <strong>„KI im Recruiting: Chancen für 2025“</strong> geben wir unser Wissen und unsere Einblicke weiter.
            Du erhältst praxisnahe Informationen, wie du Künstliche Intelligenz im Recruiting einsetzen kannst, um dein Team effizient und erfolgreich
            aufzustellen, sowie Inspiration und konkrete Tipps für deine Recruiting-Praxis.
          </p>

          <h3>
            Das erwartet dich:
          </h3>

          <ul class="mb-6">
            <li class="mb-2"><strong>Anbietervergleich: </strong> <br>Welche KI-Lösungen gibt es bereits am Markt?</li>
            <li class="mb-2"><strong>Live-Demo: </strong> <br>Eine Recruiting-KI in Aktion erleben</li>
            <li class="mb-2"><strong>Insights für 2025: </strong> <br>Wie du das Thema optimal für 2025 vorbereiten kannst</li>
          </ul>

          <h3>
            Melde dich gleich zu einem unserer Termine an:
          </h3>

		  <ul class="mb-6">
        <li>23. Januar</li>
        <li>13. Februar</li>
        <li>14. März</li>
		  </ul>

        </div>

		<div class="row align-items-center mt-5">
			<div class="col-auto">
			  <button class="btn btn-lg btn-round btn-primary" (click)="goToBooking()">Jetzt kostenlos anmelden!</button>
			</div>
		  </div>

        <!-- <a (click)="back();" class="link-arrow left text-uppercase small">Zurück</a> -->

      </div>

    </div>

  </div>

</section>
